import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute  , Router} from '@angular/router';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { LoginService } from '../services/login.service';
import { config } from '../congif';
import Swal from 'sweetalert2';
import { getCurrencySymbol } from '@angular/common';
declare var $;
@Component({
	selector: 'app-edit-client',
	templateUrl: './edit-client.component.html',
	styleUrls: ['./edit-client.component.css']
})
export class EditClientComponent implements OnInit {
	clientId:any;
	mediaUrl:any = config.baseMediaUrl;
	clientDetail:any;
	editClientForm:FormGroup;
	previewUrl:any;
	currency:any = [];
	file:File;
	typeFlag:boolean = false;
	oldProfile;
	constructor(
		public _route: ActivatedRoute,
		public _router: Router,
		public _loginService: LoginService,
		public _formBuilder: FormBuilder,
		private change: ChangeDetectorRef
	){
		this._route.params.subscribe((param)=>{
			console.log(param);
			this.clientId = param.id;
			
		});	
		this.editClientForm = new FormGroup({
			name: new FormControl('', Validators.required),
			email: new FormControl(''),
			companyName: new FormControl(''),
			street1: new FormControl(''),
			street2: new FormControl(''),
			city: new FormControl(''),
			country: new FormControl('', Validators.required),
			pincode: new FormControl(''),
			contactNo: new FormControl(''),
			userRole: new FormControl('client'),
			profilePhoto: new FormControl(''/*,  Validators.required*/),
			currency: new FormControl(''),
			userType: new FormControl('', Validators.required),
		});
	}

	ngOnInit() {
		this.getCurrency();
		this.getClientById();
	}

	ngAfterViewChecked (){
		this.change.detectChanges();
	}
	get f () { return this.editClientForm.controls }
	getCurrency(){
		this._loginService.getCurrency().subscribe((res)=>{
			console.log('res of currency' , res);

			for(let [key, value] of Object.entries(res)){
				this.currency.push(key + ' | ' + value); 	
			}
			

		}, (err)=>{
			console.log(err);
		})
	}
	getClientById(){
		this._loginService.getClientById(this.clientId).subscribe((res:any)=>{
			console.log("res of get client by id" , res);
			this.clientDetail = res[0];
			this.typeFlag = (this.clientDetail.userType == 'Company') ? true : false

			this.previewUrl = this.clientDetail.profilePhoto != ''? this.mediaUrl+this.clientDetail.profilePhoto : './assets/images/default-profile.png';
			this.oldProfile = this.clientDetail.profilePhoto;
			if(this.clientDetail.userRole == 'admin'){
				this.editClientForm.patchValue({
					userRole: "admin",
				});
			}
				this.editClientForm.updateValueAndValidity();	
			console.log(this.editClientForm.value)
			
			console.log(this.previewUrl);
		}, (err)=>{
			console.log("err of get client by id" , err);
		});
	}
	uploadFile(event){
		 this.file =  event.target.files[0];
		this.editClientForm.patchValue({
			profilePhoto : this.file
		});
		this.editClientForm.get('profilePhoto').updateValueAndValidity();
		console.log(this.editClientForm.value);
		this.preview();
	}
	preview() {
		// Show preview 
		var mimeType = this.file.type;
		if (mimeType.match(/image\/*/) == null) {
			return;
		}

		var reader = new FileReader();      
		reader.readAsDataURL(this.file); 
		reader.onload = (_event) => { 
			this.previewUrl = reader.result; 
		}
	}
	selectCurrency(event){
		// event = event.split(" ")[0];
		console.log(event);
		this.editClientForm.patchValue({
			currency : event
		});
		this.editClientForm.get('currency').updateValueAndValidity();
		console.log(this.editClientForm.value);
	}
	getType(event){
		console.log(event.target.value);
		this.typeFlag = (event.target.value == 'Company') ? true : false
		this.editClientForm.patchValue({
			userType : event.target.value
		});
		this.editClientForm.get('userType').updateValueAndValidity();
		console.log(this.editClientForm.value);
	}
	async addClient(value){
		if(value.profilePhoto == ""){
			value.profilePhoto = this.oldProfile;
			value['oldProfile'] = this.oldProfile;
		}else{
			value['oldProfile'] = this.oldProfile;
		}
		console.log(value);
		var currencySymbol = getCurrencySymbol(this.editClientForm.get('currency').value.split("|")[0].trim(), 'narrow');
		var formData = new FormData();
		formData.append('name' , this.editClientForm.get('name').value);
		formData.append('email' , this.editClientForm.get('email').value);
		formData.append('companyName' , this.editClientForm.get('companyName').value);
		formData.append('street1' , this.editClientForm.get('street1').value);
		formData.append('street2' , this.editClientForm.get('street2').value);
		formData.append('city' , this.editClientForm.get('city').value);
		formData.append('country' , this.editClientForm.get('country').value);
		formData.append('pincode' , this.editClientForm.get('pincode').value);
		formData.append('contactNo' , this.editClientForm.get('contactNo').value);
		formData.append('userRole' , this.editClientForm.get('userRole').value);
		formData.append('profilePhoto' , this.editClientForm.get('profilePhoto').value);
		formData.append('currency' , this.editClientForm.get('currency').value);
		formData.append('userType' , this.editClientForm.get('userType').value);
		formData.append('currencySymbol', currencySymbol);
		formData.append('oldPRofile' , this.oldProfile);
		formData.append('_id' , this.clientDetail._id);
		console.log("FORMDATA ++++++++++>" , formData);
		this._loginService.editClientById(formData).subscribe((res)=>{
			console.log("res of add client" , res);
			Swal.fire({
				title: 'User Edited successfully',
				// icon: 'success',
			}).then((result) => {
				if(result.value){
					this._router.navigate(['profile'], { queryParams: { userId: this.clientDetail._id } });
					this.editClientForm.reset();
				}else{
					this.editClientForm.reset();
					this.previewUrl = null;
					this.editClientForm.patchValue({
						currency : '',
					});
				}	
			})
		} , (err)=>{
			console.log("err of add client" , err);
		});		
	}
	/*enterAddress(event, name){
		event = event.target ? event.target.value : '';
		console.log("event AND name =====>", event, this.editClientForm.value);
		// console.log("VALUE CHECK ===>", event != '' ? event : "BLANK");
		// event = event.target.value;
		console.log("event == '' && this.clientDetail.address.street1 == ''", event == '' && this.clientDetail.address.street1 == '');
		if(event == '' && this.clientDetail.address.street1 == ''){
			console.log("IN ELSE =======++>", );
			$('#street2').removeAttr("disabled");
			$('#pincode').removeAttr("disabled");
			$('.street-1-error').addClass('d-none');
			// $('#street2').attr("disabled", false);
		}else{
			console.log("IN IFF =======++>");
			$('#street2').attr("disabled", true);
			$('#street2').val(""); 
			$('#pincode').attr("disabled", true);
			$('#pincode').val(""); 
			this.editClientForm.patchValue({
				street2 : '',
				pincode: ''
			});
			this.editClientForm.get('street2').updateValueAndValidity();
			this.editClientForm.get('pincode').updateValueAndValidity();
			console.log(this.editClientForm.value);
			$('.street-1-error').removeClass('d-none');

		}
	}*/
}
