import { Inject, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { Router, ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';


import * as moment from 'moment';
import Swal from 'sweetalert2';
import { LoginService } from '../services/login.service';
import { config } from '../congif';
import { InvoiceService } from '../services/invoice.service';
import { CurrencyIndex } from '@angular/common/src/i18n/locale_data';
declare var $;

@Component({
	selector: 'app-create-invoice',
	templateUrl: './create-invoice.component.html',
	styleUrls: ['./create-invoice.component.css'],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{ provide: 'Window', useValue: window }
	]
})
export class CreateInvoiceComponent implements OnInit {
	allExistingInvoiceId: any = [];
	dateToDisplay: any;
	column = [];
	value = [];
	mediaUrl: any;
	adminInfo: any;
	allClients: any;
	selectedClient: any = null;
	items: FormGroup;
	singleItem: FormGroup;
	imgPath: any = "../../assets/images/raoinfotech-logo.png";
	invoiceId: any = '';
	invoiceDate: any = '';
	allItems = [];
	totalAmount: any = 0;
	subTotal: any;
	unitPrice: any = undefined;
	quantity: any = undefined;
	clientCurrency: any;
	oldInvoiceId: any;
	editInvoiceId: any;
	editInvoiceInfo: any;
	clientAddress: any;
	editItemFlag: boolean = false
	editItemIndex: any;
	navigatedFrom: String;
	convertedAmount: any;
	convertedCurrency: any;
	currency = [];
	constructor(
		public _loginService: LoginService,
		public _formBuilder: FormBuilder,
		public _invoiceService: InvoiceService,
		public _router: Router,
		public _route: ActivatedRoute,
		@Inject('Window') private window: Window,
	) {
		this.mediaUrl = config.baseMediaUrl;
		this.items = new FormGroup({
			userId: new FormControl('', Validators.required),
			invoiceId: new FormControl('', Validators.required),
			invoiceDate: new FormControl('', Validators.required),
			totalAmount: new FormControl('', Validators.required),
			invoiceItem: new FormControl([], Validators.required),
			convertedAmount: new FormControl(''),
			convertedCurrency: new FormControl('')
		});
		this.singleItem = new FormGroup({
			itemName: new FormControl('', Validators.required),
			description: new FormControl('', Validators.required),
			quantity: new FormControl('', Validators.required),
			unitPrice: new FormControl('', Validators.required),
			subTotal: new FormControl(0, Validators.required)
		});
		this.adminInfo = JSON.parse(localStorage.getItem('currentUser'));
		if (!this.adminInfo) {
			this._router.navigate['login'];
		}
		console.log(this.adminInfo);
		this._route.queryParams
			.subscribe(params => {
				console.log(params);
				this.editInvoiceId = params.invoiceId
				this.navigatedFrom = params.navigatedFrom
			});
	}

	ngOnInit() {
		if (this.editInvoiceId) {
			// this.getInvoiceInfo();
		} else {
			this.getInvoiceInitialRecord();
			this.getClients();
			this.invoiceDate = new Date().toISOString();
			this.invoiceDate = moment(Date.now()).utc().format('YYYY-MM-DD');
			this.dateToDisplay = moment(Date.now()).utc().format('DD-MM-YYYY');
			this.items.patchValue({
				invoiceDate: this.invoiceDate
			});
			this.items.get('invoiceDate').updateValueAndValidity();
		}
		this.getInvoiceInitialRecord();
		this.getClients();
		this.getCurrency()

		$('.datepicker').datepicker();

	}
	getCurrency(){
		this._loginService.getCurrency().subscribe((res)=>{
			console.log('res of currency' , res);
			for(let [key, value] of Object.entries(res)){
				this.currency.push(key + ' | ' + value);	 	
			}
		}, (err)=>{
			console.log(err);
		})
	}
	selectCurrency(event){
		// event = event.split(" ")[0];
		console.log(event);
		this.singleItem.patchValue({
			currency : event
		});
		this.singleItem.get('currency').updateValueAndValidity();
		console.log(this.singleItem.value);
	}
	openModel() {
		// this.item = {
		// 	itemName: '',
		// 	description: '',
		// 	quantity: '',
		// 	unitPrice: '',
		// 	subTotal: 0
		// }
		this.singleItem.reset();
		this.editItemFlag = false;
		$('#myModal').modal('show');
	}
	getInvoiceInfo() {
		this._invoiceService.getInvoiceById(this.editInvoiceId).subscribe((res: any) => {
			console.log("By id", res);
			this.editInvoiceInfo = res.invoice[0];
			this.selectedClient = res.invoice[0].userId[0];
			this.invoiceDate = res.invoice[0].invoiceDate;
			this.invoiceDate = moment(this.invoiceDate).utc().format('YYYY-MM-DD');
			this.dateToDisplay = moment(moment(this.invoiceDate).add(1, 'days')).utc().format('DD-MM-YYYY');
			this.invoiceId = res.invoice[0].invoiceId;
			this.oldInvoiceId = res.invoice[0].invoiceId;
			this.allExistingInvoiceId.splice(this.allExistingInvoiceId.indexOf(this.oldInvoiceId), 1)
			this.allItems = res.invoice[0].invoiceItem;
			this.totalAmount = res.invoice[0].totalAmount;
			this.convertedAmount = res.invoice[0].convertedAmount ? res.invoice[0].convertedAmount : ''
			this.convertedCurrency = res.invoice[0].convertedCurrency ? res.invoice[0].convertedCurrency : ''
			this.clientCurrency = res.invoice[0].userId[0].currency.split(" ")[0];

			this.items.patchValue({
				userId: res.invoice[0].userId[0]._id,
				invoiceId: this.invoiceId,
				invoiceDate: this.invoiceDate,
				totalAmount: this.totalAmount,
				invoiceItem: this.allItems,
			});
			this.items.get('userId').updateValueAndValidity();
			this.items.get('invoiceId').updateValueAndValidity();
			this.items.get('invoiceDate').updateValueAndValidity();
			this.items.get('totalAmount').updateValueAndValidity();
			this.items.get('invoiceItem').updateValueAndValidity();
			console.log("after all stuff ======>", this.items.value);
		}, (err) => {
			console.log(err);
		});
	}
	getInvoiceInitialRecord() {
		this._invoiceService.getInitalInvoiceRecord().subscribe((res: any) => {
			console.log("res of get initial invoice record", res);
			if (res.falseValueInvoiceId != null || res.trueValueInvoiceId)
				this.allExistingInvoiceId = res.falseValueInvoiceId.concat(res.trueValueInvoiceId);
			this.oldInvoiceId = res.nextInvoiceId;
			this.invoiceId = res.nextInvoiceId;
			if (this.editInvoiceId) {
				this.getInvoiceInfo();
			}
			// if(this.invoiceId == 1){
			// 	$('#invoiceId').attr('readonly', true);
			// 	console.log("hey in event");
			// }else{
			// 	$('#invoiceId').attr('readonly', false);
			// }
			this.items.patchValue({
				invoiceId: this.invoiceId
			});
			this.items.get('invoiceId').updateValueAndValidity();
		}, (err) => {
			console.log("err of get initial invoice record", err);
		});
	}
	getClients() {
		this._loginService.getAllClient().subscribe((res) => {
			console.log("all clients ===>", res);
			this.allClients = res;
			console.log(this.allClients);
		}, (err) => {
			console.log("err in fetch all clients in crate invoice", err);
		});
	}
	getDeveloperDetail(event) {
		console.log("called", event);
		if (!event) {
			this.selectedClient = null;
			this.clientCurrency = null;
			this.items.patchValue({
				userId: ''
			});
			this.items.get('userId').updateValueAndValidity();
		} else {
			this.selectedClient = event;
			this.clientCurrency = event.currency.split(' ')[0];
			this.items.patchValue({
				userId: this.selectedClient._id
			});
			this.items.get('userId').updateValueAndValidity();
			console.log("Developrer added ==+>", this.items.value)
		}
	}
	addItem(item) {
		console.log("item in add item ====> ", item);

		this.allItems.push(item)
		this.items.patchValue({
			invoiceItem: this.allItems
		});
		this.items.get('invoiceItem').updateValueAndValidity();
		console.log("item added ===>", this.items.value);
		this.singleItem.reset();
		this.totalAmount = 0;
		this.getTotal();
	}
	async getTotal() {
		this.allItems.forEach((item) => {
			console.log("parseInt(item.subTotal)", parseFloat(item.subTotal), item.subTotal);
			this.totalAmount = parseFloat(this.totalAmount) + parseFloat(item.subTotal);
		});
		if (this.totalAmount % 1 != 0)
			this.totalAmount = await this.padPriceWithZero(this.totalAmount, 2)
		if (this.totalAmount != 0) {
			this.items.patchValue({
				totalAmount: this.totalAmount
			});
			this.items.get('totalAmount').updateValueAndValidity();
			// console.log("total added ===>" , this.items.value);
		}
		else {
			this.items.patchValue({
				totalAmount: ''
			});
			this.items.get('totalAmount').updateValueAndValidity();

		}
		$('#myModal').modal('hide');
	}
	removeItem(index) {
		console.log(index, this.allItems.length);
		this.totalAmount = this.totalAmount - this.allItems[index].subTotal;
		this.allItems.splice(index, 1);
		this.items.patchValue({
			invoiceItem: this.allItems,
			totalAmount: this.totalAmount
		});
		this.items.get('invoiceItem').updateValueAndValidity();
		this.items.get('totalAmount').updateValueAndValidity();
		// console.log("item added ===>" , this.items.value);

	}
	editItemModel(index) {
		this.editItemIndex = index
		let item = this.allItems[index]
		this.singleItem.patchValue({
			itemName: item.itemName,
			description: item.description,
			quantity: item.quantity,
			unitPrice: item.unitPrice,
			subTotal: item.subTotal
		});
		this.editItemFlag = true
		$('#myModal').modal('show');
	}
	editItem(item) {
		console.log("item ---=====--->", item);

		this.allItems[this.editItemIndex] = item
		this.items.patchValue({
			invoiceItem: this.allItems,
			totalAmount: this.totalAmount
		});
		this.items.updateValueAndValidity();

		this.singleItem.reset()
		this.unitPrice = undefined;
		this.quantity = undefined;
		this.totalAmount = 0;
		this.getTotal()
	}
	async getSubTotal($event) {
		this.unitPrice = (<HTMLInputElement>document.getElementById("unitPrice")).value;
		this.quantity = (<HTMLInputElement>document.getElementById("quantity")).value;
		console.log(this.unitPrice % 1);
		if (this.unitPrice % 1 != 0)
			this.unitPrice = await this.padPriceWithZero(this.unitPrice, 2)
		this.subTotal = this.unitPrice * this.quantity;
		// console.log("this.subTotal",this.subTotal);
		if (this.subTotal % 1 != 0)
			this.subTotal = await this.padPriceWithZero(this.subTotal, 2)
		console.log(this.subTotal, this.unitPrice, this.quantity)
		// this.item.subTotal = this.subTotal;
		this.singleItem.patchValue({
			subTotal: this.subTotal
		});
		this.items.updateValueAndValidity();
	}
	padPriceWithZero(number, length) {
		var my_string = number + '';
		let arr = my_string.split(".")
		let myStringLength = my_string.split(".")[1]
		console.log("myStringLength ===> ", myStringLength);
		while (myStringLength.length < length) {
			myStringLength = myStringLength + '0';
		}
		arr[1] = myStringLength
		my_string = arr.join(".")
		console.log("my_string ===>", my_string);
		return my_string;
	}
	getDate() {
		// moment((<HTMLInputElement>document.getElementById("datepicker")).value).format("DD-MM-YYYY")
		this.invoiceDate = (<HTMLInputElement>document.getElementById("datepicker")).value;
		this.invoiceDate = moment(this.invoiceDate).add(1, 'days');
		this.invoiceDate = moment(this.invoiceDate).utc().format('YYYY-MM-DD');
		this.dateToDisplay = moment(moment(this.invoiceDate).add(1, 'days')).utc().format('DD-MM-YYYY');
		// this.invoiceDate = moment(this.invoiceDate).utc().format('YYYY-MM-DD');
		console.log(this.invoiceDate);
		this.items.patchValue({
			invoiceDate: this.invoiceDate
		});
		this.items.get('invoiceDate').updateValueAndValidity();
		// console.log("date added ===>" , this.items.value);

	}
	getInvoiceNo(value) {
		console.log("parseInt", parseInt(value))
		if (parseInt(value)) {
			value = parseInt(value);
			console.log("=======>allExistingInvoiceId", value, this.allExistingInvoiceId);
			if (this.allExistingInvoiceId.includes(value)) {
				console.log("Inlcued If");
				this.items.patchValue({
					invoiceId: ''
				});
				this.items.get('invoiceId').updateValueAndValidity();
				$('#invoiceId').addClass('bg-danger');
			} else {
				console.log("Inlcued else");
				this.invoiceId = value;
				this.items.patchValue({
					invoiceId: this.invoiceId
				});
				this.items.get('invoiceId').updateValueAndValidity();
				// console.log("excludes");
				$('#invoiceId').removeClass('bg-danger');
			}
		} else {
			console.log("Inlcued else else");
			this.items.patchValue({
				invoiceId: value
			});
			this.items.get('invoiceId').updateValueAndValidity();
		}
		// console.log("invoice ID added ===>" , this.items.value);
	}
	addInvoice() {
		if (this.invoiceId != this.oldInvoiceId) {
			this.items.value['modifiedId'] = true;
		}
		this.items.value.invoiceId = this.items.value.invoiceId.toString();
		console.log(typeof (this.items.value.invoiceId))
		this.items.value.invoiceDate = this.items.value.invoiceDate == null ? new Date() : new Date(this.items.value.invoiceDate);
		// this.items.value.invoiceDate = moment(this.items.value.invoiceDate).format("YYYY-MM-DD");
		console.log("On save", this.items.value);
		this._invoiceService.addInvoice(this.items.value).subscribe((res: any) => {
			console.log("response of add invoices", res);
			Swal.fire({
				title: 'Invoice added successfully',
				text: "Do you want to add another invice or navigate to all dashboard",
				// icon: 'success',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Go to Dashboard',
				cancelButtonText: 'Add another invoice'
			}).then((result) => {
				if (result.value) {
					this._router.navigate(['']);
					this.items.reset();
					this.resetValues();

				} else {
					this.invoiceDate = moment(new Date()).format('YYYY-MM-DD');
					this.items.reset(); this.resetValues();
					/*this.addClientForm.patchValue({
						currency : '',
					});*/
				}
			});
		}, (err) => {
			console.log("error of add invoices", err);
		});
	};
	editInvoice() {
		if (this.invoiceId != this.oldInvoiceId) {
			this.items.value['modifiedId'] = true;
		} else {
			delete this.items.value.invoiceId;
		}
		this.items.value.convertedAmount = this.convertedAmount
		this.items.value.convertedCurrency = this.convertedCurrency
		
		this.items.value.invoiceDate = this.items.value.invoiceDate == null ? new Date() : new Date(this.items.value.invoiceDate);
		this.items.value['invoiceUpdateId'] = this.editInvoiceInfo._id;
		console.log("items in edit ====>", this.items.value);
		this._invoiceService.updateInvoiceById(this.items.value).subscribe((res) => {
			console.log("response of update ===>", res);
			Swal.fire({
				title: 'Invoice edited successfully',
				// icon: 'success',
			}).then((result) => {
				if (result.value) {
					this._router.navigate(['view-invoice', this.editInvoiceInfo._id]);
					this.items.reset();
					this.resetValues();
				}
			});
		}, (err) => {
			console.log("error in update ====>", err);
		});

	}
	resetValues() {
		this.singleItem.reset();
		this.unitPrice = undefined;
		this.quantity = undefined;
		this.totalAmount = 0;
		this.selectedClient = null;
		this.allItems = [];
		this.getInvoiceInitialRecord();
	}
	
	async generatePdf(value) {
		pdfMake.fonts = {
			Roboto: {
				normal: 'Roboto-Regular.ttf',
				bold: 'Roboto-Medium.ttf',
				italics: 'Roboto-Italic.ttf',
				bolditalics: 'Roboto-MediumItalic.ttf'
			}
		};
		// var printer = new pdfMake(fonts);
		this.invoiceId = await this.pad_with_zeroes(this.invoiceId, 5);
		const documentDefinition = await this.getDocumentDefinition();


		console.log("documentDefinition =====+++>", documentDefinition);
		if (value == 'save') {
			pdfMake.createPdf(documentDefinition/*, null, pdfFonts*/).download(this.invoiceId + "-" + this.selectedClient.name);
		} else if (value == 'preview') {
			pdfMake.createPdf(documentDefinition).open();
		}
		// this.getTable();
	}
	async getDocumentDefinition() {
		var dd: any = {
			info: {
				title: this.selectedClient.name + "'s Invoice ",
				author: this.selectedClient.name,
				subject: 'Invoice',
			},
			content: [
				{
					columns: [
						{
							width: 110,
							text: "         ",
							style: 'logo'
						},
						{
							width: '*',
							text: "Rao Information Technology",
							style: 'name'
						},
						{
							width: '*',
							text: 'Electronic Invoice',
							bold: true,
							fontSize: 20,
							alignment: 'right',
							margin: [0, 0, -10, 10]
						}

					],
				},
				{
					columns: [
						{
							width: 110,
							image: config.imageDataUrl,
							style: 'logo'
						},
						[
							{

								text: this.adminInfo.address.street1 + ", ",
								style: 'address'
							},
							{

								text: this.adminInfo.address.street2 + ", ",
								style: 'address'
							},
							{

								text: this.adminInfo.address.city + ", Gujarat, " + this.adminInfo.address.country + ", " + this.adminInfo.address.pincode,
								style: 'address'
							}
						],
						{
							width: '*',
							text: "         "
						}
					]
				},
				{
					columns: [
						{
							width: 110,
							text: "         ",
							style: 'logo'
						},
						[
							{

								text: "Phone : " + this.adminInfo.contactNo + ", ",
								style: 'phone'
							},
							{

								text: "Email:" + this.adminInfo.email,
								margin: [10, 0, 0, 0]
							}
						],
						{
							width: '*',
							text: "    "
						}
					]
				},
				{
					columns: [
						{
							width: '*',
							text: "__________________________________________________________________________________________________",
							margin: [-5, 20, 0, 0],
							// fontSize: 30,
							bold: true
						}
					]
				},
				{
					columns: [
						{
							width: 50,
							text: "Bill To: ",
							margin: [-5, 20, 0, 0],
							bold: true
						},
						[
							{
								text: this.selectedClient.companyName,
								margin: [-5, 20, 0, 0],
							},
							{
								text: this.selectedClient.name + ", ",
								margin: [-5, 0, 0, 0],
							},
							{

								text: this.printStreet1(),
								margin: [-5, 0, 0, 0]
							},
							{

								text: this.printStreet2(),
								margin: [-5, 0, 0, 0]
							},
							{

								text: this.printCityCountryPin(),
								margin: [-5, 0, 0, 0]
							}
						],
						{
							width: '*',
							text: "Invoice Number: ",
							alignment: 'right',
							margin: [-5, 20, 0, 0],
							bold: true
						},
						{
							width: 70,
							text: this.invoiceId,
							alignment: 'left',
							margin: [10, 20, 0, 0],
						}
					],

				},
				{
					columns: [
						{
							width: 50,
							text: "        ",
							margin: [-5, 0, 0, 0],
							bold: true
						},
						[
							{
								text: "                       ",
								margin: [-5, 0, 0, 0],
							},
							{
								text: "               ",
								margin: [-5, 0, 0, 0],
							},
							{

								text: "               ",
								margin: [-5, 0, 0, 0]
							},
							{

								text: "                     ",
								margin: [-5, 0, 0, 0]
							},
							{

								text: "                       ",
								margin: [-5, 0, 0, 0]
							}
						],
						{
							width: '*',
							text: "Invoice Date: ",
							alignment: 'right',
							margin: this.getMargin(),
							bold: true
						},
						{
							width: 70,
							text: this.dateToDisplay,
							alignment: 'right',
							margin: this.getMargin()
						}
					],
				}
			],
			defaultStyle: {
				font: 'Roboto'
			},
			styles: {
				name: {
					fontSize: 14,
					bold: true,
					alignment: 'left',
					margin: [10, 0, 0, 6],
				},
				address: {
					bold: false,
					alignment: 'left',
					margin: [10, 0, 0, 3]
				},
				logo: {
					margin: [-5, 0, 0, 0]
				},
				phone: {
					margin: [10, 9, 0, 0]
				},
				header: {
					fontSize: 18,
					bold: true,
					margin: [0, 0, 0, 10]
				},
				subheader: {
					fontSize: 16,
					bold: true,
					margin: [0, 10, 0, 5]
				},
				tableExample: {
					margin: [0, 5, 0, 15]
				},
				tableHeader: {
					bold: true,
					fontSize: 11,
					color: 'black'
				},
				tableData: {
					fontSize: 11,
					color: 'black'
				}
			}
		};
		console.log("Before functio ncall", dd.content);
		dd = await this.formatTable(dd);
		console.log("sending after increment", dd);
		return dd

	}
	formatTable(dd) {
		console.log("dd in table", dd);
		var obj = {
			width: '*',
			margin: [0, -50, 10, 20],
			style: 'tableExample',
			headerRows: 1,
			table: {
				heights: function (row) {
					// console.log("HEIGHT ============>", row);
					return 20;
				},
				margin: [20, 20, 10, 20],
				widths: [100, 170, 60, 85, 60],
				body: [
					[{ text: 'Items', style: 'tableHeader', alignment: 'left', margin: [0, 3, 0, 0] }, { text: 'Description', style: 'tableHeader', alignment: 'left', margin: [0, 3, 0, 0] }, { text: 'Quantity', style: 'tableHeader', margin: [0, 3, 0, 0] }, { text: 'Unit Price(' + this.selectedClient.currencySymbol + ')', style: 'tableHeader', margin: [0, 3, 0, 0] }, { text: 'Subtotal', style: 'tableHeader', margin: [0, 3, 0, 0] }]
				]
			},
			layout: {
				fillColor: function (rowIndex, node, columnIndex) {
					// console.log("rowIndex, node, columnIndex",rowIndex, node, columnIndex);
					return (rowIndex === 0) ? '#e5e5e5' : null;

				},
				hLineWidth: function (i, node) {
					// console.log("ROW INDEX IN HORIXSFDF ++++++++++++++++>", i, node);
					return (i === 0 || i === node.table.body.length) ? 1 : 1;
				},
				vLineWidth: function (i, node) {
					return (i === 0 || i === node.table.widths.length) ? 1 : 1;
				},
				hLineColor: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 'gray' : 'gray';
				},
				vLineColor: function (i, node) {
					return (i === 0 || i === node.table.widths.length) ? 'gray' : 'gray';
				},
			}
		}
		console.log(this.allItems);
		this.allItems.forEach((item, index) => {
			var arr = [];
			for (let [key, value] of Object.entries(item)) {
				console.log(key, value);
				var obj1 = {
					text: value.toString(), style: 'tableData', alignment: 'left', margin: [0, 3, 0, 0]
				}
				arr.push(obj1);
			}
			console.log("ARR ============+++>", arr);
			obj.table.body.push(arr);
		})
		var totalAmountObj = {
			width: '*',
			margin: [288, -21, 10, 20],
			style: 'tableExample',
			headerRows: 1,
			table: {
				margin: [20, 0, 10, 20],
				widths: [140, 77],
				body: [
					[{ text: 'Total Amount', bold: true, alignment: 'left', margin: [0, 3, 0, 3] }, { text: this.selectedClient.currencySymbol + " " + this.totalAmount, bold: true, alignment: 'left', margin: [15, 3, 0, 3] }]
				]
			},
			layout: {
				fillColor: function (rowIndex, node, columnIndex) {
					return (rowIndex === 0) ? '#e5e5e5' : null;
				},
				hLineWidth: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 1 : 1;
				},
				vLineWidth: function (i, node) {
					return (i === 0 || i === node.table.widths.length) ? 0 : 0;
				},
				hLineColor: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 'gray' : 'gray';
				}
			}
		}
		dd.content.push(obj);
		dd.content.push(totalAmountObj);
		console.log("ddd after addition ===>", dd);
		return dd;
	}
	pad_with_zeroes(number, length) {
		var my_string = '' + number;
		while (my_string.length < length) {
			my_string = '0' + my_string;
		}
		console.log("my_string ===>", my_string);
		return my_string;
	}
	printStreet1() {
		if (this.selectedClient.address.street1) {
			if (this.selectedClient.address.street2) {
				return this.selectedClient.address.street1 + ", "
			}
			else {
				return this.selectedClient.address.street1 + ", "
			}
		}
		else if (!this.selectedClient.address.street1 && this.selectedClient.address.street2) {
			return this.selectedClient.address.street2 + ", "

		}
		else {
			// return this.selectedClient.address.city+ ", "+this.selectedClient.address.country+", "+this.selectedClient.address.pincode
			if (this.selectedClient.address.pincode != "") {
				return this.selectedClient.address.city + ", " + this.selectedClient.address.country + ", " + this.selectedClient.address.pincode;
			}
			else if (this.selectedClient.address.city == "") {
				return this.selectedClient.address.country
			}
			else {
				console.log("IN ELSE OF ORINT =======++++>");
				return this.selectedClient.address.city + ", " + this.selectedClient.address.country

			}

		}
		// this.selectedClient.address.street1? this.selectedClient.address.street1+ ", " : '       '
	}
	printStreet2() {
		if (this.selectedClient.address.street1) {
			if (this.selectedClient.address.street2) {
				console.log("IN PRINTED IF N IF =====================+>");
				return this.selectedClient.address.street2 + ", "
				// return this.selectedClient.address.street2+ ", "
			}
			else if (!this.selectedClient.address.street2) {
				console.log(" INside ===============  !this.selectedClient.address.street2 ", this.selectedClient.address.pincode ? this.selectedClient.address.city + ", " + this.selectedClient.address.country + ", " + this.selectedClient.address.pincode : 'zasdfgh');
				// return this.selectedClient.address.city+ ", "+this.selectedClient.address.country+", "+this.selectedClient.address.pincode
				if (this.selectedClient.address.pincode != "") {
					return this.selectedClient.address.city + ", " + this.selectedClient.address.country + ", " + this.selectedClient.address.pincode;
				}
				else if (this.selectedClient.address.city == "") {
					return this.selectedClient.address.country
				}
				else {
					console.log("IN ELSE OF ORINT =======++++>");
					return this.selectedClient.address.city + ", " + this.selectedClient.address.country

				}
			}

		}
		else if (!this.selectedClient.address.street1 && !this.selectedClient.address.street2) {
			return '       '
		}
	}
	printCityCountryPin() {
		if (!this.selectedClient.address.street2) {
			console.log("IN IF OF ORINT =======++++>");
			// return this.selectedClient.address.pincode? this.selectedClient.address.city+ ", "+this.selectedClient.address.country+", "+this.selectedClient.address.pincode:this.selectedClient.address.city+ ", "+this.selectedClient.address.country
			return '       '
		} else {
			if (this.selectedClient.address.pincode != "") {
				return this.selectedClient.address.city + ", " + this.selectedClient.address.country + ", " + this.selectedClient.address.pincode;
			}
			else if (this.selectedClient.address.city == "") {
				return this.selectedClient.address.country
			}
			else {
				console.log("IN ELSE OF ORINT =======++++>");
				return this.selectedClient.address.city + ", " + this.selectedClient.address.country

			}
		}
	}
	getMargin() {
		if (this.selectedClient.companyName != '') {
			return [-5, -52.5, 0, 0]	//If company name present
		}
		else {
			return [-5, -38, 0, 0]	//If company name not present
		}
		// if(this.selectedClient.address.street1){
		// 	console.log("in IF");
		// 	return [-5, -38, 0, 0]
		// }
		// else if(!this.selectedClient.address.street1){
		// 	console.log("in LESE");
		// }
		// else{
		// 	return [-5, -24, 0, 0]	
		// }
		// this.selectedClient.address.street1 || this.selectedClient.address.pincode  ? : 
	}
}

