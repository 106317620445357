import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {NgxPaginationModule} from 'ngx-pagination';
declare var $;
import * as moment from 'moment';
import {InvoiceService } from '../services/invoice.service';
import { config } from '../congif';
import { SearchPipe } from '../search.pipe';
import { SplitPipe } from '../split.pipe';
@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.css'],
	providers: [SearchPipe, SplitPipe]
})
export class DashboardComponent implements OnInit {
	userInfo;
	allInvoices:any;
	mediaUrl:any;
	filteredData:any;
	sortingTitle:any = 'name';
	invoiceCount:any;
	profileImgPath:any;
	p: number = 1;
	constructor(
		public router: Router,
		public _invoiceService: InvoiceService,
		public _searchPipe: SearchPipe,
		public _splitPipe: SplitPipe
	) { }

	ngOnInit() {
		this.userInfo = JSON.parse(localStorage.getItem("currentUser"));
		if(!this.userInfo){
			this.router.navigate(['/login']);
		}
		this.mediaUrl = config.baseMediaUrl;
		this.getInvoiceCount();
		this.getLastestInvoices()
	}
	getInvoiceCount(){
		this._invoiceService.getInvoiceCount().subscribe((res:any)=>{
			this.invoiceCount = res.length;
		}, (err)=>{

		});
	}
	getLastestInvoices(){
		this._invoiceService.getLastestInvoices().subscribe((res:any)=>{
			console.log(" res of lastest invoice ", res);
			res = res.filter((singleInvoice)=>{
				singleInvoice['dateToDisplaay'] = moment(singleInvoice.invoiceDate).utc().format('DD-MM-YYYY'); 
				return singleInvoice;
			});
			this.allInvoices = res;
			// this.invoice.userId[0].profilePhoto = invoice.userId[0].profilePhoto != "" ? this.mediaUrl+invoice.userId[0].profilePhoto : 'assets/images/default-profile.png'
			const filter = res;
			this.filteredData = filter;
		},(err)=>{
			console.log(" err of lastest invoice ",err);
		});
	}
	filterData(event,Data){
		// console.log("event ==>", event.target.value, "<===>", Data);
		var filterData = this._searchPipe.dashboardTransform(Data, event.target.value);
		console.log(filterData);
		this.allInvoices = filterData;
	}
	sorting(event){
		console.log("sorting title ==>", event.target.value);
		this.sortingTitle = event.target.value;
	}
	descending(){
		switch (this.sortingTitle) {
			case "name":
			this.allInvoices.sort(function(a, b){
				var nameA=a.userId.name.toLowerCase(), nameB=b.userId.name.toLowerCase()
				if (nameA < nameB) //sort string ascending
					return 1 
				if (nameA > nameB)
					return -1
				return 0 //default return value (no sorting)
			});
			break;
			
			case "amount": 
			this.allInvoices.sort(function(a, b){
				var nameA=a.totalAmount, nameB=b.totalAmount
				if (nameA < nameB) //sort string ascending
					return 1 
				if (nameA > nameB)
					return -1
				return 0 //default return value (no sorting)
			});
			break;

			case "invoiceNo": 
			this.allInvoices.sort(function(a, b){
				var nameA=a.invoiceId, nameB=b.invoiceId
				if (nameA < nameB) //sort string ascending
					return 1 
				if (nameA > nameB)
					return -1
				return 0 //default return value (no sorting)
			});
			break;
		}

	}
	ascending(){
		switch (this.sortingTitle) {
			case "name":
			this.allInvoices.sort(function(a, b){
				var nameA=a.userId.name.toLowerCase(), nameB=b.userId.name.toLowerCase()
				if (nameA < nameB) //sort string ascending
					return -1 
				if (nameA > nameB)
					return 1
				return 0 //default return value (no sorting)
			});
			break;
			
			case "amount":
			this.allInvoices.sort(function(a, b){
				var nameA=a.totalAmount, nameB=b.totalAmount
				if (nameA < nameB) //sort string ascending
					return -1 
				if (nameA > nameB)
					return 1
				return 0 //default return value (no sorting)
			});
			break;
			case "invoiceNo": 
			this.allInvoices.sort(function(a, b){
				var nameA=a.invoiceId, nameB=b.invoiceId
				if (nameA < nameB) //sort string ascending
					return -1 
				if (nameA > nameB)
					return 1
				return 0 //default return value (no sorting)
			});
			break;
		}
	}
}
