import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { InvoiceService } from '../services/invoice.service';
import { SearchPipe } from '../search.pipe';
import { config } from '../congif';
import * as moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
declare var $;

@Component({
	selector: 'app-all-invoices',
	templateUrl: './all-invoices.component.html',
	styleUrls: ['./all-invoices.component.css'],
	providers: [SearchPipe]
})
export class AllInvoicesComponent implements OnInit {
	allInvoices:any
	mediaUrl:any;
	sortingTitle:any = 'name';
	filteredData:any;
	invoiceCount:any;
	p: number = 1;
	adminInfo
	constructor(
		public _invoiceService: InvoiceService,
		public _searchPipe: SearchPipe,
		private change: ChangeDetectorRef,
		public _router: Router,
		public _route: ActivatedRoute
	) {
		this.adminInfo = JSON.parse(localStorage.getItem('currentUser'));
		if(!this.adminInfo){
			this._router.navigate['login'];
		}
		$(document).ready(()=>{
			
			var start = moment().startOf('month');
			var end = moment().endOf('month');

			let cb = (start, end) => {
				this.getRangeDate(start, end);
			}

			$('#reportrange').daterangepicker({
				startDate: start,
				endDate: end,
				ranges: {
					'Today': [moment()/*.add(1 , 'days')*/, moment()],
					'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days') ],
					'Last 7 Days': [moment().subtract(6, 'days'), moment()],
					'Last 30 Days': [moment().subtract(29, 'days'), moment()],
					'This Month': [moment().startOf('month'), moment().endOf('month')],
					'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
				}
			}, cb);

			cb(start, end);

		});
		$('[data-toggle="tooltip"]').tooltip();   
		// });
	}

	ngOnInit() {
		// this.getRangeDate(moment().startOf('month'), moment().endOf('month'));
		this.mediaUrl = config.baseMediaUrl;
		this.getRangeDate(moment().startOf('month'), moment().endOf('month'));
		this.getInvoiceCount();
	}
	getInvoiceCount(){
		this._invoiceService.getInvoiceCount().subscribe((res:any)=>{
			this.invoiceCount = res.length;
		}, (err)=>{
			console.log(err);
		});
	}
	getRangeDate(start, end){
		var increseStartDate:any = moment(start._d).add(1 , 'days');
		// new Date(moment(start._d).add(1 , 'days')).toISOString()
		var body = {
			
			startDate : new Date(increseStartDate).toISOString(),
			endDate : new Date(end._d).toISOString()
		}
		
		console.log(body);
		this._invoiceService.getInvoiceByDate(body).subscribe((res:any)=>{
			res = res.filter((singleInvoice)=>{
				singleInvoice['dateToDisplaay'] = moment(moment(singleInvoice.invoiceDate).add(1 , 'days')).utc().format('DD-MM-YYYY'); 
				return singleInvoice;
			});
			this.allInvoices = res;
			this.change.detectChanges();
			console.log("this.allInvoices",this.allInvoices);	
			const filter = res;
			this.filteredData = filter;
		}, (err)=>{
			console.log(err);
		});

	}
	filterData(event,Data){
		// console.log("event ==>", event.target.value, "<===>", Data);
		var filterData = this._searchPipe.dashboardTransform(Data, event.target.value);
		console.log(filterData);
		this.allInvoices = filterData;
		this.change.detectChanges();
	}
	sorting(event){
		console.log("sorting title ==>", event.target.value);
		this.sortingTitle = event.target.value;
	}

	descending(){
		switch (this.sortingTitle) {
			case "name":
			this.allInvoices.sort(function(a, b){
				var nameA=a.userId[0].name.toLowerCase(), nameB=b.userId[0].name.toLowerCase()
				if (nameA < nameB) //sort string ascending
					return 1 
				if (nameA > nameB)
					return -1
				return 0 //default return value (no sorting)
			});
			break;
			
			case "amount": 
			this.allInvoices.sort(function(a, b){
				var nameA=a.totalAmount, nameB=b.totalAmount
				if (nameA < nameB) //sort string ascending
					return 1 
				if (nameA > nameB)
					return -1
				return 0 //default return value (no sorting)
			});
			break;

			case "invoiceNo": 
			this.allInvoices.sort(function(a, b){
				var nameA=a.invoiceId, nameB=b.invoiceId
				if (nameA < nameB) //sort string ascending
					return 1 
				if (nameA > nameB)
					return -1
				return 0 //default return value (no sorting)
			});
			break;
		}

	}
	ascending(){
		switch (this.sortingTitle) {
			case "name":
			this.allInvoices.sort(function(a, b){
				var nameA=a.userId[0].name.toLowerCase(), nameB=b.userId[0].name.toLowerCase()
				if (nameA < nameB) //sort string ascending
					return -1 
				if (nameA > nameB)
					return 1
				return 0 //default return value (no sorting)
			});
			break;
			
			case "amount":
			this.allInvoices.sort(function(a, b){
				var nameA=a.totalAmount, nameB=b.totalAmount
				if (nameA < nameB) //sort string ascending
					return -1 
				if (nameA > nameB)
					return 1
				return 0 //default return value (no sorting)
			});
			break;
			case "invoiceNo": 
			this.allInvoices.sort(function(a, b){
				var nameA=a.invoiceId, nameB=b.invoiceId
				if (nameA < nameB) //sort string ascending
					return -1 
				if (nameA > nameB)
					return 1
				return 0 //default return value (no sorting)
			});
			break;
		}
	}
}
