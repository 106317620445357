import { Component, OnInit } from '@angular/core';
import {NgxPaginationModule} from 'ngx-pagination';
import { Router , ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service';

import { config } from '../congif';
import { SearchPipe } from '../search.pipe';

import Swal from 'sweetalert2';
@Component({
	selector: 'app-client',
	templateUrl: './client.component.html',
	styleUrls: ['./client.component.css'],
	providers: [SearchPipe]
})
export class ClientComponent implements OnInit {
	totalClients:any;
	allClient:any;	
	userInfo:any;
	mediaUrl = config.baseMediaUrl;
	filterData;
	sortingTitle:any = "name";
	p: number = 1;
	constructor(
		public _router: Router,
		public _loginService: LoginService,
		public _searchPipe: SearchPipe
	){ 
		this.userInfo = JSON.parse(localStorage.getItem('currentUser'));
		if(!this.userInfo){
			this._router.navigate(['login']);	
		}
	}

	ngOnInit() {
		this.getAllClient();
	}
	getAllClient(){
		this._loginService.getAllClient().subscribe((res:any)=>{
			console.log("response of all clients" , res);
			this.allClient = res;	
			this.totalClients = res.length;
			const filter = res;
			this.filterData = filter;

		}, (err)=>{
			console.log("error of all clients" , err);
		})
	}
	onSearchChange(value){
		var filteredData = this._searchPipe.transform(this.filterData,value);
		this.allClient = filteredData;
	}
	deleteClient(clientId , name){
		console.log(clientId);
		Swal.fire({
			title: 'Delete '+name ,
			text: "Are you sure you want to delete ?",
			// icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Delete',
			cancelButtonText: 'Cancel'
		}).then((result) => {
			if(result.value){
				Swal.fire({
					title: 'IMP Decision' ,
					text: "Do you want to delete all the invoices of "+name,
					// icon: 'warning',
					showCancelButton: true,
					confirmButtonColor: '#3085d6',
					cancelButtonColor: '#d33',
					confirmButtonText: 'Yes',
					cancelButtonText: 'No'
				}).then((result)=>{
					if(result.value == true){
						console.log("true");

					}else{
						console.log("fflase");
						this._loginService.deleteClientWithoutInvoice(clientId).subscribe((res)=>{
							console.log(res);
							this.getAllClient();
						}, (err)=>{
							console.log(err);
						});
					}
				});		
				/*this._loginService.deleteClientById(clientId).subscribe((res)=>{
					console.log("res of deleted user" , res);
					Swal.fire({
						position: 'center',
						icon: 'success',
						title: 'User deleted Successfully',
						showConfirmButton: false,
						timer: 1500
					});
					this.getAllClient();
				}, (err)=>{
					console.log("err in deleeting user " , err);
					Swal.fire({
						position: 'center',
						icon: 'error',
						title: 'Some problem occured in deleting client',
						showConfirmButton: false,
						timer: 1500
					})
				});*/
			}	
		});
	}
	sorting(event){
		this.sortingTitle = event.target.value
	}
	descending(){
		switch (this.sortingTitle) {
			case "name":
				this.allClient.sort(function(a, b){
					var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase()
					if (nameA < nameB) //sort string ascending
						return 1 
					if (nameA > nameB)
						return -1
					return 0 //default return value (no sorting)
				});
				break;
			
			case "date": 
				this.allClient.sort(function(a, b){
					var nameA=a.date, nameB=b.date
					if (nameA < nameB) //sort string ascending
						return 1 
					if (nameA > nameB)
						return -1
					return 0 //default return value (no sorting)
				});
				break;
		}

	}
	ascending(){
		switch (this.sortingTitle) {
			case "name":
				this.allClient.sort(function(a, b){
					var nameA=a.name.toLowerCase(), nameB=b.name.toLowerCase()
					if (nameA < nameB) //sort string ascending
						return -1 
					if (nameA > nameB)
						return 1
					return 0 //default return value (no sorting)
				});
				break;
			
			case "date":
				this.allClient.sort(function(a, b){
					var nameA=a.date.toLowerCase(), nameB=b.date.toLowerCase()
					if (nameA < nameB) //sort string ascending
						return -1 
					if (nameA > nameB)
						return 1
					return 0 //default return value (no sorting)
				});
				break;
		}
	}	
}
