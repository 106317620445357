import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {NgxPaginationModule} from 'ngx-pagination';
import { InvoiceService } from '../services/invoice.service';
import { SearchPipe } from '../search.pipe';
import { config } from '../congif';
@Component({
	selector: 'app-client-wise-invoice',
	templateUrl: './client-wise-invoice.component.html',
	styleUrls: ['./client-wise-invoice.component.css'],
	providers: [SearchPipe]
})
export class ClientWiseInvoiceComponent implements OnInit {
	userId:any;	
	totalInvoices:any;
	totalAmount:any;
	clientDetails:any;
	allInvoices:any;
	clientCurrency:any;
	invoiceFound:boolean = true
	filteredData:any;
	mediaUrl:any;
	p: number = 1;
	sortingTitle
	adminInfo;
	constructor(
		public _route: ActivatedRoute,
		public _invoiceService: InvoiceService,
		public _searchPipe: SearchPipe,
		public _router: Router
		) {
		this.adminInfo = JSON.parse(localStorage.getItem('currentUser'));
		if(!this.adminInfo){
			this._router.navigate['login'];
		}
		this._route.params.subscribe((params)=>{
			console.log(params);
			this.userId = params.id
		});
	}

	ngOnInit() {
		this.mediaUrl = config.baseMediaUrl
		this.getInvoiceByUserId()
	}
	getInvoiceByUserId(){
		this._invoiceService.getInvoiceByClientId(this.userId).subscribe((res:any)=>{
			console.log("res ==>", res);
			if(res.invoices.length > 0){
				this.invoiceFound = true;
				this.totalAmount = res.totalAmount;
				
				this.clientDetails = res.invoices[0].userId[0];
				console.log("this.clientDetails ====>", this.clientDetails);
				this.totalInvoices = res.invoices.length;
				this.allInvoices = res.invoices;
				const filter =  res.invoices;
				this.filteredData = filter;
			}else{
				this.clientDetails = res.user[0]
				this.invoiceFound = false
				this.totalInvoices = res.invoices.length
			}
		}, (err)=>{
			console.log("err ==>", err);
		});
	}
	filterData(event,Data){
		// console.log("event ==>", event.target.value, "<===>", Data);
		var filterData = this._searchPipe.clientWiseInvoiceTransform(Data, event.target.value);
		console.log(filterData);
		this.allInvoices = filterData;
	}
	sorting(event){
		console.log("sorting title ==>", event.target.value);
		this.sortingTitle = event.target.value;
	}
	descending(){
		switch (this.sortingTitle) {
			case "date":
			this.allInvoices.sort(function(a, b){
				var nameA=a.invoiceDate, nameB=b.invoiceDate
				if (nameA < nameB) //sort string ascending
					return 1 
				if (nameA > nameB)
					return -1
				return 0 //default return value (no sorting)
			});
			break;
			
			case "amount": 
			this.allInvoices.sort(function(a, b){
				var nameA=a.totalAmount, nameB=b.totalAmount
				if (nameA < nameB) //sort string ascending
					return 1 
				if (nameA > nameB)
					return -1
				return 0 //default return value (no sorting)
			});
			break;

			case "invoiceNo": 
			this.allInvoices.sort(function(a, b){
				var nameA=a.invoiceId, nameB=b.invoiceId
				if (nameA < nameB) //sort string ascending
					return 1 
				if (nameA > nameB)
					return -1
				return 0 //default return value (no sorting)
			});
			break;
		}

	}
	ascending(){
		switch (this.sortingTitle) {
			case "date":
			this.allInvoices.sort(function(a, b){
				var nameA=a.invoiceDate, nameB=b.invoiceDate
				if (nameA < nameB) //sort string ascending
					return -1 
				if (nameA > nameB)
					return 1
				return 0 //default return value (no sorting)
			});
			break;
			
			case "amount":
			this.allInvoices.sort(function(a, b){
				var nameA=a.totalAmount, nameB=b.totalAmount
				if (nameA < nameB) //sort string ascending
					return -1 
				if (nameA > nameB)
					return 1
				return 0 //default return value (no sorting)
			});
			break;
			case "invoiceNo": 
			this.allInvoices.sort(function(a, b){
				var nameA=a.invoiceId, nameB=b.invoiceId
				if (nameA < nameB) //sort string ascending
					return -1 
				if (nameA > nameB)
					return 1
				return 0 //default return value (no sorting)
			});
			break;
		}
	}
	viewProfile(){
		this._router.navigate(['profile'], { queryParams: { userId: this.userId } });
	}
}
