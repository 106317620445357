import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClientComponent } from './client/client.component';
import { ClientWiseInvoiceComponent } from './client-wise-invoice/client-wise-invoice.component';
import { AddClientComponent } from './add-client/add-client.component';
import { EditClientComponent } from './edit-client/edit-client.component';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component';

import { ViewClientComponent } from './view-client/view-client.component';
import { AllInvoicesComponent } from './all-invoices/all-invoices.component';
import { from } from 'rxjs';

const routes: Routes = [
  {
    path : 'login',
		component : LoginComponent
  },
  {
    path : '',
    component : DashboardComponent
  },
  {
    path: 'clients',
    component: ClientComponent
  },
  {
    path: 'add-client',
    component: AddClientComponent
  },
  {
    path: 'edit-client/:id',
    component: EditClientComponent
  },
  {
    path: 'client-wise-invoice/:id',
    component: ClientWiseInvoiceComponent
  },
  {
    path: 'create-invoice',
    component: CreateInvoiceComponent

  },
  {
    path: 'view-invoice/:invoiceId',
    component: ViewInvoiceComponent
  },
  {
    path: 'profile',
    component: ViewClientComponent
  },
  {
    path: 'all-invoices',
    component: AllInvoicesComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
