import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute }  from '@angular/router';

import { LoginService } from '../services/login.service';
import { config } from '../congif';
declare var google;
@Component({
	selector: 'app-view-client',
	templateUrl: './view-client.component.html',
	styleUrls: ['./view-client.component.css']
})
export class ViewClientComponent implements OnInit {
	userId:any;
	userDetail:any;
	mediaUrl:any;
	startBtn:any;
	stopBtn:any;
	adminInfo:any;	
	constructor(
		public _route: ActivatedRoute,
		public _router: Router,
		public _loginService: LoginService
	) { 
		this.adminInfo = JSON.parse(localStorage.getItem('currentUser'));
		if(!this.adminInfo){
			this._router.navigate['login'];
		}
		this._route.queryParams
		.subscribe(params => {
			console.log(params); 
			this.userId = params.userId
		});
		
	}

	ngOnInit(  ) {
		this.mediaUrl = config.baseMediaUrl;
		this.getClientById();
		this.getGoogleMap()
	}
	getClientById(){
		this._loginService.getClientById(this.userId).subscribe((res)=>{
			console.log("res of getclient by id in view ", res);
			this.userDetail = res[0];
		}, (err)=>{
			console.log("errro " , err);
		})	
	}
	getGoogleMap(){
		this._loginService.getLocation().subscribe((res)=>{
			console.log(res);
		}, (err)=>{
			console.log(err);
		})
	}
}
