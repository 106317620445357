import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClientComponent } from './client/client.component';
import { AddClientComponent } from './add-client/add-client.component';
import { EditClientComponent } from './edit-client/edit-client.component';
import { ClientWiseInvoiceComponent } from './client-wise-invoice/client-wise-invoice.component';

//imported modules
import { HttpClientModule, HTTP_INTERCEPTORS }    from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPaginationModule } from 'ngx-pagination';
import { SearchPipe } from './search.pipe';

import { LoadingScreenInterceptor } from './loading.interceptor';


import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { ViewInvoiceComponent } from './view-invoice/view-invoice.component';

import { ViewClientComponent } from './view-client/view-client.component';
import { AllInvoicesComponent } from './all-invoices/all-invoices.component';
import { LoadingScreenComponent } from './loading-screen/loading-screen.component';
import { SplitPipe } from './split.pipe';
import { AddZeroPipe } from './add-zero.pipe';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    ClientComponent,
    AddClientComponent,
    EditClientComponent,
    ClientWiseInvoiceComponent,
    SearchPipe,
    CreateInvoiceComponent,
    ViewInvoiceComponent,
    
    ViewClientComponent,
    AllInvoicesComponent,
    LoadingScreenComponent,
    SplitPipe,
    AddZeroPipe,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    NgSelectModule,
    NgxPaginationModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
