import { Component, OnInit } from '@angular/core';
import { FormControl , FormGroup , Validators } from '@angular/forms'

import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../services/login.service'
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
	loginForm: FormGroup;	
	isDisable: boolean = false;
	isError : boolean = false;
	errorMessage;
	constructor(
		public _loginService: LoginService,
		public router: Router 
	) { 
		if (this._loginService.currentUserValue) { 
			this.router.navigate(['/']);
		}
		this.loginForm = new FormGroup({
			userName: new FormControl('' , Validators.required),
			password: new FormControl('' , Validators.required)
		});	


	}

	ngOnInit() {

	}
	get f () { return this.loginForm.controls }

	login(loginCredentails){
		console.log("loginCredentails ==>" , loginCredentails);
		this._loginService.loginUser(loginCredentails).subscribe((res)=>{
			this.isDisable = false;
			this.router.navigate(['']);
			this.isError = false;
			console.log("res of login USer" ,  res);
		} , (err)=>{
			this.isError = true;
			if (err.status == 404){
				this.errorMessage = "Enter correct user name / password";
			}
			console.log(this.errorMessage)
			console.log("err of login USer" ,  this.isError);
		});
	}
}
