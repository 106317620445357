import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router , NavigationExtras} from '@angular/router';

import { config } from '../congif';
import * as moment from 'moment';
import { InvoiceService } from '../services/invoice.service';
import Swal from 'sweetalert2';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
@Component({
	selector: 'app-view-invoice',
	templateUrl: './view-invoice.component.html',
	styleUrls: ['./view-invoice.component.css']
})
export class ViewInvoiceComponent implements OnInit {
	invoiceId:any;
	invoiceInfo:any;
	selectedClient:any;
	adminInfo:any;
	dateToDisplay:any;
	constructor(
		public _route: ActivatedRoute,
		public _router: Router,
		public _invoiceService: InvoiceService
	) {
		this.adminInfo = JSON.parse(localStorage.getItem('currentUser'));
		if(!this.adminInfo){
			this._router.navigate['login'];
		}
		this._route.params.subscribe((param)=>{
			console.log(param);
			this.invoiceId = param.invoiceId;
			console.log(this.invoiceId);
		});	
	}

	ngOnInit() {
		this.getInvoiceById();
	}
	 getInvoiceById(){
		this._invoiceService.getInvoiceById(this.invoiceId).subscribe( async(res:any)=>{
			console.log("res of invoice by id ", res.invoice[0]);
			var date = res.invoice[0].invoiceDate; 
			if(res.invoice[0].totalAmount % 1 != 0)
				res.invoice[0].totalAmount = await this.padPriceWithZero( res.invoice[0].totalAmount, 2);
			res.invoice[0].invoiceDate = moment(date).utc().format('YYYY-MM-DD')
			this.dateToDisplay = moment(moment(res.invoice[0].invoiceDate).add(1 , 'days')).utc().format('DD-MM-YYYY');

			this.invoiceInfo = res.invoice[0];
			this.selectedClient = res.invoice[0].userId[0];

		}, (err)=>{
			console.log("err of invoice by id ", err);
		});
	}
	padPriceWithZero(number, length){
		var my_string =  number +'' ;
		let arr = my_string.split(".")
		let myStringLength = my_string.split(".")[1]
		console.log("myStringLength ===> ", myStringLength);
		while (myStringLength.length < length) {
			myStringLength =  myStringLength + '0' ;
		}
		arr[1] = myStringLength
		my_string = arr.join(".")
		console.log("my_string ===>", my_string);
		return my_string;
	}
	saveEditedInvoice(){
		// var navigationExtra : NavigationExtras= {
		// 	queryParams: { 'invoiceId': this.invoiceInfo._id},
		// 	fragment: 'anchor',
		// } 

		this._router.navigate(['create-invoice'], { queryParams: { invoiceId: this.invoiceInfo._id, navigatedFrom: 'editInvoice' } });
	}
	deleteInvoice(){
		Swal.fire({
			title: 'Delete invoice of '+this.invoiceInfo.invoiceId,
			text: "Are you sure you want to delete ?",
			// icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Delete',
			cancelButtonText: 'Cancel'
		})
		.then((result)=>{
			if(result.value){
				this._invoiceService.deleteInvoiceById(this.invoiceInfo._id).subscribe((res:any)=>{
					console.log(res);
					if(res.nModified == 1 || res.deletedCount == 1){
						Swal.fire({
							title: 'Invoice deleted successfuly',
							// icon: 'success',

						})
						.then((result)=>{
							this._router.navigate(['']);
						});
					}
				}, (err)=>{
					console.log(err);
				});
			}
		});
	}
	async generatePdf(value){
		this.invoiceInfo.invoiceId =  await this.pad_with_zeroes(this.invoiceInfo.invoiceId	, 5);
		
		

		// const documentDefinition = { content: 'This is an sample PDF printed with pdfMake' };	
		if(value == 'save'){
			const documentDefinition = await this.getDocumentDefinition();
			console.log("THIS IS INVOICE ID IN ON DOWNLOAD", this.invoiceInfo.invoiceId);
			pdfMake.createPdf(documentDefinition).download(this.invoiceInfo.invoiceId+"-"+this.selectedClient.name);
		}else if(value == 'preview'){
			const documentDefinition = await this.getDocumentDefinition();
			pdfMake.createPdf(documentDefinition).open();
		}else if(value == 'save-converted'){
			console.log("save converted")
			const documentDefinition = await this.getDocumentDefinitionForConvertedAmount();
			console.log("documentDefinition", documentDefinition)
			pdfMake.createPdf(documentDefinition).download("Converted invoice for "+this.invoiceInfo.invoiceId)
			// pdfMake.createPdf(documentDefinition).open()
		}
		// this.getTable();
	}
	async getDocumentDefinition(){
		var dd:any =  {
			info: {
				title: this.selectedClient.name+"'s Invoice ",
				author: this.selectedClient.name,
				subject: 'Invoice',
			},
			content: [
				{
					columns: [
							{
								width: 110,
								text: "         ",
								style: 'logo'
							},
							{
								width: '*',
								text: "Rao Information Technology",
								style: 'name'
							},
							{
								width: '*',
								text: 'Electronic Invoice',
								bold: true,
								fontSize: 20,
								alignment: 'right',
								margin: [0, 0, -10, 10]
							}
						
					],
				},
				{
					columns: [
						{
							width: 110,
							image: config.imageDataUrl,
								style: 'logo' 
						},
						[
							{
								
								text: this.adminInfo.address.street1+", ",
								style: 'address'
							},
							{
								
								text: this.adminInfo.address.street2+", ",
								style: 'address'
							},
							{	
								
								text: this.adminInfo.address.city+", Gujarat, "+this.adminInfo.address.country+", "+this.adminInfo.address.pincode,
								style: 'address'
							}
						],
						{
							width: '*',
							text: "         " 	
						}
					]
				},
				{
					columns: [
						{
							width: 110,
								text: "         ", 
								style: 'logo'
						},
						[
							{
								
								text: "Phone : " + this.adminInfo.contactNo+", ",
								style: 'phone'
							},
							{
								
								text:"Email:"+ this.adminInfo.email,
								margin: [10,0,0,0]
							}
						],
						{
							width: '*',
							text: "    " 	
						}
					]
				},
				{
					columns: [
						{
							width: '*',
							text: "__________________________________________________________________________________________________",
							margin: [-5, 20, 0, 0],
							// fontSize: 30,
							bold: true 	
						}
					]
				},
				{
					columns: [
						{
							width: 50,
								text: "Bill To: ", 
								margin: [-5, 20, 0, 0],
								bold: true
						},
						[
							{
								text: this.selectedClient.companyName,
								margin: [-5, 20, 0, 0],
							},	
							{
								text: this.selectedClient.name+", ",
								margin: [-5, 0, 0, 0],
							},
							{
								
								text: this.printStreet1(),
								margin: [-5, 0, 0, 0]
							},
							{
								
								text: this.printStreet2(),
								margin: [-5, 0, 0, 0]
							},
							{
								
								text: this.printCityCountryPin(),
								margin: [-5, 0, 0, 0]
							}
						],
						{
							width: '*',
							text: "Invoice Number: ",
							alignment: 'right',
							margin: [0, 20, 25, 0],
							bold: true	
						},
						{
							width: 70,
							text: this.invoiceInfo.invoiceId ,
							alignment: 'left',
							margin: [-18, 20, 0, 0], 	
						}
					],

				},
				{
					columns: [
						{
							width: 50,
								text: "        ", 
								margin: [-5, 20, 0, 0],
								bold: true
						},
						[
							{
								text: "                       ",
								margin: [-5, 0, 0, 0],
							},
							{
								text: "               ",
								margin: [-5, 0, 0, 0],
							},
							{
								
								text: "               ",
								margin: [-5, 0, 0, 0]
							},
							{
								
								text:"                     ",
								margin: [-5, 0, 0, 0]
							},
							{
								
								text: "                       ",
								margin: [-5, 0, 0, 0]
							}
						],
						{
							width: '*',
							text: "Invoice Date: ",
							alignment: 'right',
							margin: this.getMargin(),
							bold: true	
						},
						{
							width: 70,
							text: this.dateToDisplay ,
							alignment: 'right',
							margin: this.getMargin(), 	
						}
					],
				},
				
			],
			styles: {
				name: {
					fontSize: 14,
					bold: true,
					alignment: 'left',
					margin: [10, 0, 0, 6],
				},
				address: {
					bold: false,
					alignment: 'left',
					margin: [10, 0, 0, 3]
				},
				logo: {
					margin: [-5, 0, 0, 0]
				},
				phone:  {
					margin: [10, 9, 0, 0]	
				},
				header: {
					fontSize: 18,
					bold: true,
					margin: [0, 0, 0, 10]
				},
				subheader: {
					fontSize: 16,
					bold: true,
					margin: [0, 10, 0, 5]
				},
				tableExample: {
					margin: [0, 5, 0, 15],
				},
				tableHeader: {
					bold: true,
					fontSize: 11,
					color: 'black'
				},
				tableData: {
					fontSize: 11,
					color: 'black'
				}
			}
		};
		console.log("Before functio ncall",dd.content);
		dd = await this.formatTable(dd);
		console.log("sending after increment" , dd);	
		return dd
		
	}
	formatTable(dd){
		console.log("dd in table" , dd);
		var obj = {

			width: '*',
			margin: [0, -50, 10, 20],
			style: 'tableExample',
			headerRows: 1,
			table: {
				heights: function (row) {
					// console.log("HEIGHT ============>", row);
					return 20;
				},
				margin: [20, 20, 10, 20],
				widths: [100, 170, 60, 85, 60],
				body: [
					[{text:'Items',style: 'tableHeader', alignment: 'left', margin: [0, 3, 0, 0]}, 
					{text: 'Description', style: 'tableHeader', alignment: 'left', margin: [0, 3, 0, 0]}, 
					{text: 'Quantity', style: 'tableHeader', margin: [0, 3, 0, 0]}, 
					{text: 'Unit Price('+this.selectedClient.currencySymbol+')', style: 'tableHeader', margin: [0, 3, 0, 0]}, 
					{text: 'Subtotal', style: 'tableHeader', margin: [0, 3, 0, 0]}
				]
				]
			},
			layout: {
				fillColor: function (rowIndex, node, columnIndex) {
					console.log("rowIndex, node, columnIndex",rowIndex, node, columnIndex);
					return (rowIndex === 0) ? '#e5e5e5' : null;
				},
				hLineWidth: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 1 : 1;
				},
				vLineWidth: function (i, node) {
					return (i === 0 || i === node.table.widths.length) ? 1 : 1;
				},
				hLineColor: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 'gray' : 'gray';
				},
				vLineColor: function (i, node) {
					return (i === 0 || i === node.table.widths.length) ? 'gray' : 'gray';
				},
			}
		}
		console.log(this.invoiceInfo.invoiceItem);
		this.invoiceInfo.invoiceItem.forEach((item, index)=>{
			var arr = [];
			for(let [key, value] of Object.entries(item)){
				console.log(key, value);
				var obj1 = {
					text:value.toString(),style: 'tableData', alignment: 'left', margin: [0, 3, 0, 0]
				}
				arr.push(obj1);
			}
			
			obj.table.body.push(arr);
		});
		var totalAmountObj = {
			width: '*',
			margin: [288, -21,10, 20],
			style: 'tableExample',
			headerRows: 1,
			table: {
				margin: [20, 0, 10, 20],
				widths: [140, 77],
				body: [
					[{text: 'Total Amount',bold:true, alignment: 'left', margin: [0, 3, 0, 3]}, {text: this.selectedClient.currencySymbol+" "+this.invoiceInfo.totalAmount, bold: true, alignment: 'left', margin:[15,3,0,3]}]
				]
			},
			layout: {
				fillColor: function (rowIndex, node, columnIndex) {
					console.log("rowIndex, node, columnIndex",rowIndex, node, columnIndex);
					return (rowIndex === 0) ? '#e5e5e5' : null;
				},
				hLineWidth: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 1 : 1;
				},
				vLineWidth: function (i, node) {
					return (i === 0 || i === node.table.widths.length) ? 0 : 0;
				},
				hLineColor: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 'gray' : 'gray';
				}
			}
		}
		console.log(this.invoiceInfo.totalAmount);
		dd.content.push(obj);
		dd.content.push(totalAmountObj);
		console.log("ddd after addition ===>", dd);
		return dd;
	}

	async getDocumentDefinitionForConvertedAmount(){
		var dd:any =  {
			info: {
				title: this.selectedClient.name+"'s Invoice ",
				author: this.selectedClient.name,
				subject: 'Invoice',
			},
			content: [
				{
					columns: [
							{
								width: 110,
								text: "         ",
								style: 'logo'
							},
							{
								width: '*',
								text: "Rao Information Technology",
								style: 'name'
							},
							{
								width: '*',
								text: 'Electronic Invoice',
								bold: true,
								fontSize: 20,
								alignment: 'right',
								margin: [0, 0, -10, 10]
							}
						
					],
				},
				{
					columns: [
						{
							width: 110,
							image: config.imageDataUrl,
								style: 'logo' 
						},
						[
							{
								
								text: this.adminInfo.address.street1+", ",
								style: 'address'
							},
							{
								
								text: this.adminInfo.address.street2+", ",
								style: 'address'
							},
							{	
								
								text: this.adminInfo.address.city+", Gujarat, "+this.adminInfo.address.country+", "+this.adminInfo.address.pincode,
								style: 'address'
							}
						],
						{
							width: '*',
							text: "         " 	
						}
					]
				},
				{
					columns: [
						{
							width: 110,
								text: "         ", 
								style: 'logo'
						},
						[
							{
								
								text: "Phone : " + this.adminInfo.contactNo+", ",
								style: 'phone'
							},
							{
								
								text:"Email:"+ this.adminInfo.email,
								margin: [10,0,0,0]
							}
						],
						{
							width: '*',
							text: "    " 	
						}
					]
				},
				{
					columns: [
						{
							width: '*',
							text: "__________________________________________________________________________________________________",
							margin: [-5, 20, 0, 0],
							// fontSize: 30,
							bold: true 	
						}
					]
				},
				{
					columns: [
						{
							width: 50,
								text: "Bill To: ", 
								margin: [-5, 20, 0, 0],
								bold: true
						},
						[
							{
								text: this.selectedClient.companyName,
								margin: [-5, 20, 0, 0],
							},	
							{
								text: this.selectedClient.name+", ",
								margin: [-5, 0, 0, 0],
							},
							{
								
								text: this.printStreet1(),
								margin: [-5, 0, 0, 0]
							},
							{
								
								text: this.printStreet2(),
								margin: [-5, 0, 0, 0]
							},
							{
								
								text: this.printCityCountryPin(),
								margin: [-5, 0, 0, 0]
							}
						],
						{
							width: '*',
							text: "Invoice Number: ",
							alignment: 'right',
							margin: [0, 20, 25, 0],
							bold: true	
						},
						{
							width: 70,
							text: this.invoiceInfo.invoiceId ,
							alignment: 'left',
							margin: [-18, 20, 0, 0], 	
						}
					],

				},
				{
					columns: [
						{
							width: 50,
								text: "        ", 
								margin: [-5, 20, 0, 0],
								bold: true
						},
						[
							{
								text: "                       ",
								margin: [-5, 0, 0, 0],
							},
							{
								text: "               ",
								margin: [-5, 0, 0, 0],
							},
							{
								
								text: "               ",
								margin: [-5, 0, 0, 0]
							},
							{
								
								text:"                     ",
								margin: [-5, 0, 0, 0]
							},
							{
								
								text: "                       ",
								margin: [-5, 0, 0, 0]
							}
						],
						{
							width: '*',
							text: "Invoice Date: ",
							alignment: 'right',
							margin: this.getMargin(),
							bold: true	
						},
						{
							width: 70,
							text: this.dateToDisplay ,
							alignment: 'right',
							margin: this.getMargin(), 	
						}
					],
				},
				
			],
			styles: {
				name: {
					fontSize: 14,
					bold: true,
					alignment: 'left',
					margin: [10, 0, 0, 6],
				},
				address: {
					bold: false,
					alignment: 'left',
					margin: [10, 0, 0, 3]
				},
				logo: {
					margin: [-5, 0, 0, 0]
				},
				phone:  {
					margin: [10, 9, 0, 0]	
				},
				header: {
					fontSize: 18,
					bold: true,
					margin: [0, 0, 0, 10]
				},
				subheader: {
					fontSize: 16,
					bold: true,
					margin: [0, 10, 0, 5]
				},
				tableExample: {
					margin: [0, 5, 0, 15],
				},
				tableHeader: {
					bold: true,
					fontSize: 11,
					color: 'black'
				},
				tableData: {
					fontSize: 11,
					color: 'black'
				}
			}
		};
		console.log("Before function call",dd.content);
		dd = await this.formatTableForConvertedAmount(dd);
		console.log("sending after increment" , dd);	
		return dd
		
	}
	formatTableForConvertedAmount(dd){
		console.log("dd in table" , dd);
		var obj = {

			width: '*',
			margin: [0, -50, 10, 20],
			style: 'tableExample',
			headerRows: 1,
			table: {
				heights: function (row) {
					// console.log("HEIGHT ============>", row);
					return 20;
				},
				margin: [20, 20, 10, 20],
				widths: [100, 170, 60, 85, 60],
				body: [
					[{text:'Items',style: 'tableHeader', alignment: 'left', margin: [0, 3, 0, 0]}, 
					{text: 'Description', style: 'tableHeader', alignment: 'left', margin: [0, 3, 0, 0]}, 
					{text: 'Quantity', style: 'tableHeader', margin: [0, 3, 0, 0]}, 
					{text: 'Unit Price('+this.selectedClient.currencySymbol+')', style: 'tableHeader', margin: [0, 3, 0, 0]}, 
					{text: 'Subtotal', style: 'tableHeader', margin: [0, 3, 0, 0]}
				]
				]
			},
			layout: {
				fillColor: function (rowIndex, node, columnIndex) {
					console.log("rowIndex, node, columnIndex",rowIndex, node, columnIndex);
					return (rowIndex === 0) ? '#e5e5e5' : null;
				},
				hLineWidth: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 1 : 1;
				},
				vLineWidth: function (i, node) {
					return (i === 0 || i === node.table.widths.length) ? 1 : 1;
				},
				hLineColor: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 'gray' : 'gray';
				},
				vLineColor: function (i, node) {
					return (i === 0 || i === node.table.widths.length) ? 'gray' : 'gray';
				},
			}
		}
		console.log(this.invoiceInfo.invoiceItem);
		this.invoiceInfo.invoiceItem.forEach((item, index)=>{
			var arr = [];
			for(let [key, value] of Object.entries(item)){
				console.log(key, value);
				var obj1 = {
					text:value.toString(),style: 'tableData', alignment: 'left', margin: [0, 3, 0, 0]
				}
				arr.push(obj1);
			}
			
			obj.table.body.push(arr);
		});
		var totalAmountObj = {
			width: '*',
			margin: [288, -21,10, 20],
			style: 'tableExample',
			headerRows: 1,
			table: {
				margin: [20, 0, 10, 20],
				widths: [140, 77],
				body: [
					[
						{text: 'Total Amount',bold:true, alignment: 'left', margin: [0, 3, 0, 3]}, {text: this.selectedClient.currencySymbol+" "+this.invoiceInfo.totalAmount, bold: true, alignment: 'left', margin:[15,3,0,3]},
					]
				]
			},
			layout: {
				fillColor: function (rowIndex, node, columnIndex) {
					console.log("rowIndex, node, columnIndex",rowIndex, node, columnIndex);
					return (rowIndex === 0) ? '#e5e5e5' : null;
				},
				hLineWidth: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 1 : 1;
				},
				vLineWidth: function (i, node) {
					return (i === 0 || i === node.table.widths.length) ? 0 : 0;
				},
				hLineColor: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 'gray' : 'gray';
				}
			}
		}
		var convertedAmountObj = {
			width: '*',
			margin: [288, -21,10, 20],
			style: 'tableExample',
			headerRows: 1,
			table: {
				margin: [20, 0, 10, 20],
				widths: [140, 77],
				body: [
					[
						{text: 'Converted Amount',bold:true, alignment: 'left', margin: [0, 3, 0, 3]}, {text: this.invoiceInfo.convertedAmount + " " + this.invoiceInfo.convertedCurrency.split("|")[0].split(" ")[0], bold: true, alignment: 'left', margin:[15,3,0,3]},
					]
				]
			},
			layout: {
				fillColor: function (rowIndex, node, columnIndex) {
					console.log("rowIndex, node, columnIndex",rowIndex, node, columnIndex);
					return (rowIndex === 0) ? '#e5e5e5' : null;
				},
				hLineWidth: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 1 : 1;
				},
				vLineWidth: function (i, node) {
					return (i === 0 || i === node.table.widths.length) ? 0 : 0;
				},
				hLineColor: function (i, node) {
					return (i === 0 || i === node.table.body.length) ? 'gray' : 'gray';
				}
			}
		}
		dd.content.push(obj);
		dd.content.push(totalAmountObj);
		dd.content.push(convertedAmountObj);
		console.log("ddd after addition ===>", dd);
		return dd;
	}

	pad_with_zeroes(number, length) {

		var my_string = '' + number;
		while (my_string.length < length) {
			my_string = '0' + my_string;
		}
		console.log("my_string ===>", my_string);
		return my_string;

	}
	printStreet1(){
		if(this.selectedClient.address.street1 ){
			if(this.selectedClient.address.street2 ){
				return this.selectedClient.address.street1+ ", " 
			}
			else{
				return this.selectedClient.address.street1+", "
			}
		}
		else if(!this.selectedClient.address.street1 && this.selectedClient.address.street2){
			return this.selectedClient.address.street2+ ", "

		}
		else{
			// return this.selectedClient.address.city+ ", "+this.selectedClient.address.country+", "+this.selectedClient.address.pincode
			if(this.selectedClient.address.pincode != ""){
				return this.selectedClient.address.city+ ", "+this.selectedClient.address.country+", "+this.selectedClient.address.pincode;	
			}
			else if(this.selectedClient.address.city == ""){
				return this.selectedClient.address.country 				
			}
			else{
				console.log("IN ELSE OF ORINT =======++++>");
				return this.selectedClient.address.city+ ", "+this.selectedClient.address.country

			}

		}
		// this.selectedClient.address.street1? this.selectedClient.address.street1+ ", " : '       '
	}
	printStreet2(){
		if(this.selectedClient.address.street1){
			if(this.selectedClient.address.street2){
				console.log("IN PRINTED IF N IF =====================+>");
				return this.selectedClient.address.street2+ ", "
				// return this.selectedClient.address.street2+ ", "
			}
			else if(!this.selectedClient.address.street2 ){
				console.log(" INside ===============  !this.selectedClient.address.street2 ",this.selectedClient.address.pincode ? this.selectedClient.address.city+ ", "+this.selectedClient.address.country+", "+this.selectedClient.address.pincode: 'zasdfgh');
				// return this.selectedClient.address.city+ ", "+this.selectedClient.address.country+", "+this.selectedClient.address.pincode
				if(this.selectedClient.address.pincode != ""){
					return this.selectedClient.address.city+ ", "+this.selectedClient.address.country+", "+this.selectedClient.address.pincode;	
				}
				else if(this.selectedClient.address.city == ""){
					return this.selectedClient.address.country 				
				}
				else{
					console.log("IN ELSE OF ORINT =======++++>");
					return this.selectedClient.address.city+ ", "+this.selectedClient.address.country

				}
			}

		}
		else if(!this.selectedClient.address.street1 && !this.selectedClient.address.street2){
			return '       '
		}
	}
	printCityCountryPin(){
		if(!this.selectedClient.address.street2){
			console.log("IN IF OF ORINT =======++++>");
			// return this.selectedClient.address.pincode? this.selectedClient.address.city+ ", "+this.selectedClient.address.country+", "+this.selectedClient.address.pincode:this.selectedClient.address.city+ ", "+this.selectedClient.address.country
			return '       '
		}else{
			if(this.selectedClient.address.pincode != ""){
				return this.selectedClient.address.city+ ", "+this.selectedClient.address.country+", "+this.selectedClient.address.pincode;	
			}
			else if(this.selectedClient.address.city == ""){
				return this.selectedClient.address.country 				
			}
			else{
				console.log("IN ELSE OF ORINT =======++++>");
				return this.selectedClient.address.city+ ", "+this.selectedClient.address.country

			}
		} 
	}
	getMargin(){
		if(this.selectedClient.companyName != ''){
			return [-5, -52.5, 0, 0]	//If company name present
		}
		else{
			return [-5, -38, 0, 0]	//If company name not present
		}
		
		// if(this.selectedClient.address.street1){
		// 	console.log("in IF");
		// 	return [-5, -38, 0, 0]
		// }
		// else if(!this.selectedClient.address.street1){
		// 	console.log("in LESE");
		// }
		// else{
		// 	return [-5, -24, 0, 0]	
		// }
		// this.selectedClient.address.street1 || this.selectedClient.address.pincode  ? : 
	}
}
	