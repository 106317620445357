import { Component , OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from './services/login.service';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
	// userInfo : any;	
	userInfo = JSON.parse(localStorage.getItem("currentUser"));
	constructor( private route: ActivatedRoute,
		private router: Router, private loginService: LoginService ) {  
		// this.userInfo = JSON.parse(localStorage.getItem("currentUser"));
		this.loginService.isLoggedIn.subscribe((data) => {
			if(data === 'loggedIn') {
				this.userInfo = JSON.parse(localStorage.getItem("currentUser"));
			}
		})
	}

	ngOnInit() {
		console.log("called");
		if(!this.userInfo){
			this.router.navigate(['/login']);
		}else{
			console.log("called 2nd time");
			console.log(this.userInfo);
			this.userInfo = JSON.parse(localStorage.getItem("currentUser"));

		}
		


	}
	getNotification(evt){
		console.log(evt);
	}
	viewProfile(){
		this.router.navigate(['profile'], { queryParams: { userId: this.userInfo._id } });
	}
	logout(){
		Swal.fire({
			title: 'Are you sure?',
			text: "Logout!",
			// icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Logout!'
		}).then((result) => {
			if (result.value) {
				this.loginService.logout();
				this.router.navigate(['login']);
			}
		})
	}
}

