import { Injectable , EventEmitter} from '@angular/core';
import { HttpClient , HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable  , of , pipe} from 'rxjs';

// import {  } from '@angular/forms'
import { config } from '../congif'
@Injectable({
	providedIn: 'root'
})
export class LoginService {
	// data:any;
	formData:any = new FormData();
	isLoggedIn: EventEmitter<any> = new EventEmitter<any>();
	private currentUserSubject: BehaviorSubject<any>;
	public currentUser: Observable<any>;
	constructor(
		public _http: HttpClient 
		)
	{
		this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
		this.currentUser = this.currentUserSubject.asObservable(); 
	}
	public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }
    getCurrency(){
    	return this._http.get('https://openexchangerates.org/api/currencies.json');
    }
    getCurrencySymbol(shortForm){
    	console.log(shortForm);
    	return this._http.get('https://restcountries.eu/rest/v2/currency/'+shortForm);
	}
    loginUser(body){
		return this._http.post(  config.baseApiUrl+"user/login" , body)
		.pipe(map(user => {
			console.log("login user=========>", user);
			if (user) {
				localStorage.setItem('currentUser', JSON.stringify(user));
				this.isLoggedIn.emit('loggedIn');
				this.currentUserSubject.next(user);
			}

			return user;
		}));
	}


	//get All Clients
	getAllClient(){
		return this._http.get(config.baseApiUrl+'user/get-all-client');
	}

	//add client
	addClient( addClientForm ){
		// this.formData.append("image" , addClientForm.get('image').value)
		// console.log(addClientForm.get('image').value);
		// console.log(this.formData);

		return this._http.post(config.baseApiUrl+'user/signup' , addClientForm);
	}

	//get client by id 
	getClientById(clientId){
		const headers = new HttpHeaders({'Content-Type':'application/json'});
		console.log(clientId);
		return this._http.post(config.baseApiUrl+'user/get-client-by-id/'+clientId , {headers: headers});
	}

	//edit client By id
	editClientById(editClientForm){
		// const headers = new HttpHeaders({'Content-Type':'application/json'});
		return this._http.put( config.baseApiUrl+'user/update-client-by-id' , editClientForm );	
	}

	//delete user By id
	deleteClientById(clientId){
		const headers = new HttpHeaders({'Content-Type':'application/json'});
		return this._http.post(config.baseApiUrl+"user/delete-client-by-id/"+clientId , {headers: headers});
	}
	deleteClientWithoutInvoice(clientId){
	const headers = new HttpHeaders({'Content-Type':'application/json'});
		return this._http.post(config.baseApiUrl+"user/delete-client-without-invoice/"+clientId , {headers: headers});
	}
	logout(){
		    // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
     	this.currentUserSubject.next(null);
	}


	/*GET GEO LOCATION*/
	getLocation(){
		return this._http.get("https://www.mapquestapi.com/geocoding/v1/address?key=hw80D7MfLqGBumHIn7AFTrAzA8WuSS3v&inFormat=kvp&outFormat=json&location=raiya%road%2C+Gujarat&thumbMaps=false");
	}
}
