import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { config } from '../congif';
@Injectable({
	providedIn: 'root'
})
export class InvoiceService {

	constructor(
		public 	_http: HttpClient
	) { }
	/*GET INITIAL INVOICE ID AND ALL EXISTING INVOICE*/
	getInitalInvoiceRecord(){
		return this._http.get(config.baseApiUrl+'invoice/get-inital-invoice-record');
	}
	addInvoice(body){
		console.log(body);
		return this._http.post(config.baseApiUrl+'invoice/add-invoice' , body);
	}
	getCurrencySymbol(shortForm){
		return this._http.get('https://restcountries.eu/rest/v2/currency/'+shortForm);
	}

	/*GET LASTEST INVOICES FOR DASHBOARD*/
	getLastestInvoices(){
		return this._http.get(config.baseApiUrl+'invoice/get-lastest-invoice');
	}
	/*GET INVOICE BY ID*/
	getInvoiceById(id){
		var body = {
			invoiceId : id
		}
		return this._http.post(config.baseApiUrl+'invoice/get-invoice-by-id', body);
	}

	/*GET INVOICE BY CLIENT*/
	getInvoiceByClientId(id){
		var body = {
			userId : id
		}	
		return this._http.post(config.baseApiUrl+'invoice/get-invoice-by-client', body);
	}

	/*UPDATE INVOICE BY ID*/
	updateInvoiceById(body){
		return this._http.post(config.baseApiUrl+'invoice/update-invoice-by-id', body);
	}

	/*GET INVOICE BY DATE*/
	getInvoiceByDate(body){
		return this._http.post(config.baseApiUrl+'invoice/get-invoice-by-date', body);
	}

	/*DELETE INOVICE BY ID*/
	deleteInvoiceById(id){
		return this._http.delete(config.baseApiUrl+'invoice/delete-invoice-by-id/'+id);
	}
	getInvoiceCount(){
		return this._http.get(config.baseApiUrl+'invoice/get-total-invoice-count');
	}
}
